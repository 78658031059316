import "../styles/globals.css";
import "../styles/pourcentage.css";
import "../styles/exterior.css";
import "../styles/visit.css";
import "../styles/treeD.css";
import "../styles/hero.css";
import "../styles/skills.css";
import "../styles/spliter.css";
import { useState, useEffect } from "react";
import AppContext from "../components/context/AppContext";

import Router from "next/router";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/react";
import ErrorBoundary from "../config/ErrorBoundary ";
import Script from "next/script";
import Head from "next/head";

function MyApp({ Component, pageProps }) {
  const [pourcentageColor, setPourcentageColor] = useState("white");
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [windowsWidth, setWindowsWidth] = useState();
  const [windowsHeight, setWindowsHeight] = useState();
  const [langContext, setLangContext] = useState("fr");
  const [totalPagination, setTotalPagination] = useState(4);

  const [loadPage, setLoadPage] = useState(false);

  const startLoading = () => setLoadPage(true);
  const stopLoading = () => setLoadPage(false);

  useEffect(() => {
    Router.events.on("routeChangeStart", startLoading);
    Router.events.on("routeChangeComplete", stopLoading);
    Router.events.on("routeChangeError", stopLoading);

    return () => {
      Router.events.off("routeChangeStart", startLoading);
      Router.events.off("routeChangeComplete", stopLoading);
      Router.events.off("routeChangeError", stopLoading);
    };
  }, []);

  return (
    <>
      <ErrorBoundary>
        <AppContext.Provider
          value={{
            pourcentageColor,
            setPourcentageColor,
            pageNumber,
            setPageNumber,
            loading,
            setLoading,
            windowsWidth,
            setWindowsWidth,
            windowsHeight,
            setWindowsHeight,
            langContext,
            setLangContext,
            totalPagination,
          }}
        >
          <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
          </Head>
          {loadPage && (
            <div className="pageLoading">
              <BounceLoader css={css} size={30} color={"#E6B697"} loading={loadPage} />
            </div>
          )}

          <Component {...pageProps} />
        </AppContext.Provider>
      </ErrorBoundary>

      <Script strategy="afterInteractive" src="https://www.googletagmanager.com/gtag/js?id=G-W8XW2EC2QD" />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', 'G-W8XW2EC2QD');
        `}
      </Script>
    </>
  );
}

export default MyApp;
